/*

NOTE: tag is not supported in horizontal menu

Array of object

Top level object can be:
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- nav Grp (top level grp is called header group) ---/

title
icon (if it's on top/second level)
children

/--- nav Item (top level item is called header link) ---/

icon (if it's on top/second level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)

*/
import device from './device'
import event from './event'
import auth from './auth'
import label from './label'
// import custody from './custody'
import dashboard from './dashboard'
let menu = [
    // ...device,...event,...auth,...label,...dashboard,...custody,...group
    {
      title: '分组管理',
      icon: 'HomeIcon',
      children: [
        {
          title: '分组配置',
          route: 'group-config',
        },
        {
          title: '分组设置',
          route: 'group-setting',
        },
      ],
    },
  ]
  
  const userData = getUserData()
  console.log('userData1111111111',userData)
  if (userData.urlPermissionTree) {
    menu = userData.urlPermissionTree
  }
  console.log(menu)
  export default menu